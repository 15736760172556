<template>
  <v-overlay v-if="progressBar" z-index="999">
    <v-progress-circular indeterminate size="64" color="primary" />
  </v-overlay>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AppProgressCircular',

  computed: {
    ...mapGetters({
      progressBar: 'ui/progressBar'
    })
  }
}
</script>
